.Scanpage {
    .scan-section {
        width: 1000px;
        margin-top: 10px;
        height: 100%;

        @media (max-width: 1200px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: 1200px) {
                width: 80%;
            }
    
            .title-decoration {
                width: 4px;
                height: 32px;
                background: linear-gradient(166deg, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
                margin-right: 2%;
    
                @media (max-width: 1100px) {
                    height: 25px;
                }
            }
    
            h1 {
                font-family: 'Oswald';
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: white;
    
                @media (max-width: 1100px) {
                    font-size: 18px;
                }
            }
    
            @media (max-width: 850px) {
                width: 80%;
            }
        }

        .scancontainer {
            @media (max-width: 1200px) {
                width: 100%;
            }

            .swiper-wrapper {
                align-items: center;

                .swiper-slide {
                    display: flex;
                    justify-content: center;

                    .chapterpage {
                        max-height: 700px;
                        width: auto;
                        object-fit: contain;

                        @media (max-width: 1200px) {
                            max-width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .swiper-button-prev {
                background: linear-gradient(to top, #0C7DF1, #371CF1, #A01DE5, #EF0F64, #DD6F0F);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .swiper-button-next {
                background: linear-gradient(to top, #0C7DF1, #371CF1, #A01DE5, #EF0F64, #DD6F0F);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .swiper-pagination {
                color: white;
                bottom: 0;
                text-align: end;
                font-size: 20px;
                font-weight: bolder;
                -webkit-text-stroke: 1px;
                -webkit-text-stroke-color: black;
            }
        }

        .scan-nav {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;

            @media (max-width: 400px) {
                gap: 4%;
            }

            &>a {
                @media (min-width: 850px) {
                    position: relative;
                }

                &:hover:before, &:hover:after {
                    @media (min-width: 850px) {
                        content: '';
                        position: absolute;
                        left: -1px;
                        top: -1px;
                        background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
                        background-size: 400%;
                        width: 111px;
                        height: 48px;
                        z-index: -1;
                        animation: steam 20s linear infinite;
                        border-radius: 8px;
                    }
                }

                &:hover:after {
                    @media (min-width: 850px) {
                        filter: blur(50px);
                    }
                }
            }
        }
        
        .scan-report {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 10px;
            display: block;
            color: #FFFFFF;
        }
    }
}



