.faq-section {
    border: solid 1px gray;
    background-color: #000;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 30px 3%;
    margin-top: 70px;
    border-radius: 10px;

    @media (max-width: 800px) {
        margin-top: 30px;
    }

    @media (max-width: 500px) {
        margin-top: 20px;
        padding: 20px 3%;
    }

    h2 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 18px;

        @media (max-width: 1100px) {
            font-size: 15px;
            margin-bottom: 5px;
        }

        @media (max-width: 500px) {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }
}

.legal-section {
    border: solid 1px gray;
    background-color: #000;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: 80px 0 49px 0;
    padding: 30px 3%;
    border-radius: 10px;

    p {
        &:last-child {
            font-weight: bold;
        }
    }
}

.faq-section, .legal-section {
    h1 {
        margin: 0;
        margin-bottom: 30px;
        font-size: 30px;

        @media (max-width: 1100px) {
            font-size: 25px;
        }

        @media (max-width: 500px) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    p {
        margin: 0;
        margin-right: 5%;
        margin-bottom: 20px;
        text-align: justify;

        @media (max-width: 1100px) {
            font-size: 12px;
            margin-bottom: 15px;
        }

        @media screen and (max-width: 500px) {
            font-size: 11px;
            margin-bottom: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}