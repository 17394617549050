.episodes-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        margin-top: 30px;
    }

    &-aside {
        margin-top: 10px;
    }
}

.episode-div {
    width: 288px;
    height: 55px;
    background: #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    position: relative;
    background-clip: padding-box;
    border: solid 1px transparent;
    opacity: 1 !important;

    @media (max-width: 1500px) {
        justify-content: center;
    }

    @media (max-width: 600px) {
        width: 250px;
        height: 50px;
        justify-content: center;
    }

    @media (max-width: 500px) {
        width: 200px;
        height: 40px;
    }
    
    &:last-child {
        @media (max-width: 500px) {
            margin-bottom: 20px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -2px 0;
        z-index: -1;
        border-radius: inherit;
        background: linear-gradient(to right, #0C7DF1, #371CF1, #A01DE5, #EF0F64, #DD6F0F);
    }

    a {
        margin-left: 14px;
        font-size: 24px;
        color: #FFFFFF;
        text-decoration: none;
        transition: 0.5s all;

        @media (max-width: 1500px) {
            margin-left: 0;
        }

        @media (max-width: 600px) {
            font-size: 20px;
        }

        @media (max-width: 500px) {
            font-size: 18px;
        }

        &:hover {
            font-size: 26px;
            color: #838383;

            @media (max-width: 600px) {
                font-size: 22px;
            }

            @media (max-width: 500px) {
                font-size: 20px;
            }
        }
    }
}

