.Saisonpage {
    .saison-section {
        width: 1000px;
        margin-top: 10px;
        height: 50%;

        @media (max-width: 1200px) {
            width: 80%;
        }

        @media (max-width: 900px) {
            width: 80%;
        }

        @media (max-width: 850px) {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            .title-decoration {
                width: 4px;
                height: 32px;
                background: linear-gradient(166deg, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
                margin-right: 2%;
    
                @media (max-width: 1100px) {
                    height: 25px;
                }
            }
    
            h1 {
                font-family: 'Oswald';
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: white;
    
                @media (max-width: 1100px) {
                    font-size: 18px;
                }
            }
    
            @media (max-width: 850px) {
                width: 80%;
            }
        }

        .saison-container {
            color: white;
            display: flex;
            gap: 30px;
            width: 80%;

            @media (max-width: 900px) {
                margin-bottom: 20px;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
            }

            img {
                @media (max-width: 900px) {
                    max-height: 350px;
                    object-fit: contain;
                }
            }

            h1 {
                font-size: 20px;
                margin-bottom: 10px;

                @media (max-width: 900px) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }

            h2 {
                font-size: 32px;
                margin: 0;

                @media (max-width: 900px) {
                    font-size: 25px;
                }
            }

            p {
                text-align: justify;

                @media (max-width: 900px) {
                    font-size: 12px;
                }
            }

            div {
                &>a {
                    max-width: 109px;

                    @media (min-width: 850px) {
                        position: relative;
                        display: block;
                    }

                    &:hover:before, &:hover:after {
                        @media (min-width: 850px) {
                            content: '';
                            position: absolute;
                            left: -1px;
                            top: -1px;
                            background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
                            background-size: 400%;
                            width: 111px;
                            height: 48px;
                            z-index: -1;
                            animation: steam 20s linear infinite;
                            border-radius: 8px;
                        }
                    }
                    
                    &:hover:after {
                        @media (min-width: 850px) {
                            filter: blur(50px);
                        }
                    }
                }
            }
        }
    }
}

