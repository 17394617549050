.Header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  
  @media screen and (max-width: 850px) {
    border-image-source: linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
    border-bottom: 2px solid;
    background: linear-gradient(black, black) 50% 50%/calc(100%) calc(100%) no-repeat, linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
  }

  .header-content {
    height: 100%;
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      width: 80%;
    }

    
    img {
      @media (max-width: 850px) {
        width: 71px;
        height: 23px;
      }
    }

    .header-menu {
      width: 500px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        height: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.017em;
        color: #FFFFFF;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        width: 150px;
        background-color: #000;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1 !important;
          background: linear-gradient(black, black) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat,
            linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
          box-sizing: border-box;

          @media screen and (max-width: 1200px) {  
            box-sizing: initial;
          }
        }
      }
    }

    .menu-footer {
      width: 100%;
      height: 70px;
      background-color: black;
      position: fixed;
      bottom: 0;
      left: 0;
      border-image-source: linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
      border-top: 2px solid;
      background: linear-gradient(black, black) 50% 50%/calc(100%) calc(100%) no-repeat, linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
  
      @media (display-mode: fullscreen) {
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
  
      a {
        @media (display-mode: fullscreen) {
          height: 100%;
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          gap: 2px;
          opacity: 0.4;
        }
  
        &:first-child {
          @media (display-mode: fullscreen) {
            margin-left: 15px;
          }
        }

        &:last-child {
          @media (display-mode: fullscreen) {
            margin-right: 15px;
          }
        }
      }
  
      p {
        @media (display-mode: fullscreen) {
          color: white;
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          margin-top: 0;
        }
      }
  
      .active {
        @media (display-mode: fullscreen) {
          background: none !important;
          opacity: 1 !important;
        }
      }
  }
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 17px;
  z-index: 2000 !important;
}

.bm-burger-bars {
  background: linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
  border-radius: 5px;
}

.bm-burger-bars-hover {
  background: linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #cbce0d 100%);
}

.bm-cross-button {
  display: none;
}

.bm-cross {
  display: none;
}

.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100%;
  width: 160px !important;
}

.bm-menu {
  background: #000;
  position: fixed;
  top: 65px;
  right: 0;
  width: 160px;
  font-size: 1.15em;
}

.bm-item-list {
  color: #b8b7ad;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  height: 50% !important;
}

.bm-item {
  padding-right: 60px;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 62px);
  height: 50px;
  text-align: center;
  text-decoration: none;
  color: white;
  opacity: 0.5;
  background-color: #000;

  &.active {
    opacity: 1 !important;
    background: linear-gradient(black, black) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat,
      linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
    box-sizing: border-box;

    @media screen and (max-width: 1200px) {  
      box-sizing: initial;
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 64px;
}

