.Scanmenupage {
    .scans-section {
        width: 1000px;
        margin-top: 10px;
        height: 100%;

        @media (max-width: 1200px) {
            width: 80%;
        }

        @media (max-width: 850px) {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            .title-decoration {
                width: 4px;
                height: 32px;
                background: linear-gradient(166deg, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
                margin-right: 2%;
    
                @media (max-width: 1100px) {
                    height: 25px;
                }
            }
    
            h1 {
                font-family: 'Oswald';
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: white;
    
                @media (max-width: 1100px) {
                    font-size: 18px;
                }
            }
    
            @media (max-width: 850px) {
                width: 80%;
            }
        }

        .articles-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 4%;

            @media (max-width: 850px) {
                overflow: initial;
                flex-wrap: wrap !important;
                width: 90% !important;
                justify-content: center;
            }

            article {
                width: 22%;
                margin-bottom: 3%;
                position: relative;

                @media (max-width: 850px) {
                    gap: 0;
                    display: inline-block !important;
                    width: 148px;
                }

                &>a:hover img {
                    @media screen and (min-width: 850px) {
                        box-shadow: 0 0 20px 5px #48abe0;
                    }
                }

                .scan-description {
                    h2 {
                        color: #fff;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 21px;

                        @media (max-width: 900px) {
                            font-size: 14px;
                        }
                    }
                }

                img {
                    @media (max-width: 1200px) {
                        max-width: 213px;
                    }
                    @media (min-width: 900px) {
                        height: auto;
                        width: 100%;
                    }
                    @media (max-width: 850px) {
                        height: 210px!important;
                        width: 148px!important;
                    }
                }
    
                p {
                    width: 100%;
                    height: 180px;
                    font-family: 'Arial';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #B7B7B7;
    
                    &:last-child {
                        margin-top: 0;
                        font-style: italic;
                        min-height: 70px;

                        @media (max-width: 1200px) {
                            height: auto;
                        }
    
                        a {
                            color: #B7B7B7;
                            text-decoration: underline;
                            background: linear-gradient(90deg, #0C7DF1 0%, #EF0F64 48.23%, #DD6F0F 100%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            &:hover {
                                font-size: 110%;
                            }
                        }
                    }
                }
    
                .btnlinks-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    gap: 5px;
    
                    @media (max-width: 1200px) {
                        justify-content: center;
                    }
    
                    &>a {
                        @media (min-width: 850px) {
                            position: relative;
                        }
                        &:hover:before, &:hover:after {
                            @media (min-width: 850px) {
                                content: '';
                                position: absolute;
                                left: -1px;
                                top: -1px;
                                background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
                                background-size: 400%;
                                width: 111px;
                                height: 48px;
                                z-index: -1;
                                animation: steam 20s linear infinite;
                                border-radius: 8px;
                            }
                        }
    
                        &:hover:after {
                            @media (min-width: 850px) {
                                filter: blur(50px);
                            }
                        }
                    }
                }
            }
        }
    }
}