.Episode {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 100px;
    width: 77.5% !important;
    align-self: self-end;

    @media screen and (max-width: 1500px) {
        flex-direction: column !important;
        align-items: center;
        margin-left: 0;
        align-self: center;
    }

    .episode-section {
        margin-top: 30px;
        align-self: flex-start;

        @media screen and (max-width: 1500px) {
            width: 100%;
        }

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 46px;
            margin-right: 30px;

            @media screen and (max-width: 1100px) {
                height: 35px;
            }

            @media screen and (max-width: 850px) {
                margin-right: 0;
                width: 100%;
            }

            &.second {
                @media screen and (max-width: 850px) {
                    flex-direction: column;
                    align-items: flex-start;
                    height: auto;
                }
            }

            &:nth-child(2n) {
                margin-bottom: 5px;
            }

            .left-part {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 46px;
                width: 50%;

                @media screen and (max-width: 850px) {
                    justify-content: flex-start;
                    width: 100%;
                }
            }
            
            .right-part {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                height: 46px;
                width: 50%;
                position: relative;

                @media screen and (max-width: 850px) {
                    justify-content: flex-start;
                    width: 100%;
                }

                h2 {
                    font-family: "Oswald";
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: white;
                    margin-right: 10px;

                    @media (max-width: 1100px) {
                        font-size: 18px;
                    }
                }

                .select-container {
                    position: relative;
                    display: inline-block;
                    border-radius: 8px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -1px;
                        left: -1px;
                        height: 48px;
                        width: 112px;
                        border-radius: 8px;
                        z-index: -1;
                        background-image: linear-gradient(to right, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);

                        @media (max-width: 1100px) {
                            height: 32px;
                        }
                    }

                    select {
                        height: 46px;
                        width: 110px;
                        background-color: black;
                        color: white;
                        border: 0;
                        text-align: center;
                        padding: 8px;
                        border-radius: 8px;
                        font-size: 14px;
                        letter-spacing: 0.10em;
                        font-family: Oswald;

                        @media (max-width: 1100px) {
                            height: 30px;
                            padding: 4px;
                        }

                        option {
                            height: 40px;
                            text-align: left;
                        }
                    }
                }
            }
    
            .title-decoration {
                width: 4px;
                height: 32px;
                background: linear-gradient(166deg, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
                margin-right: 12px;
    
                @media (max-width: 1100px) {
                    height: 25px;
                }
            }

            h1 {
                font-family: 'Oswald';
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: white;
    
                @media (max-width: 1100px) {
                    font-size: 18px;
                    margin: 0;
                }
            }
        }

        .episode-iframe-section {
            iframe {
                width: 854px;
                aspect-ratio: 16/9;

                @media screen and (max-width: 1500px) {
                    width: 100%;
                }
            }

            .episode-nav {
                display: flex;
                gap: 10px;
                justify-content: flex-end;
                margin-top: 10px;
                margin-right: 30px;

                @media screen and (max-width: 1500px) {
                    justify-content: center;
                    margin-right: 0;
                }

                &>a {
                    @media screen and (min-width: 850px) {
                        position: relative;
                    }

                    &:hover:before, &:hover:after {
                        @media screen and (min-width: 850px) {
                            content: '';
                            position: absolute;
                            left: -1px;
                            top: -1px;
                            background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
                            background-size: 400%;
                            width: 111px;
                            height: 48px;
                            z-index: -1;
                            animation: steam 20s linear infinite;
                            border-radius: 8px;
                        }
                    }

                    &:hover:after {
                        @media screen and (min-width: 850px) {
                            filter: blur(50px);
                        }
                    }
                }
            }

            .episode-report {
                text-align: end;
                margin-top: 10px;
                margin-right: 45px;
                font-size: 10px;
                display: block;
                color: #FFFFFF;

                
                @media screen and (max-width: 1500px) {
                    text-align: center;
                    margin-right: 0;
                }
            }
        }
    }
}






