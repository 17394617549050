@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter/Inter.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("../assets/fonts/Oswald/Oswald.ttf");
    font-display: swap;
}