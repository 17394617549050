/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #4d4d4d #1a1a1a;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #bdbdbd;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
  border: 2px solid rgb(56, 56, 56);
}