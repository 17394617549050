.Homepage {
    .animes-section {
        width: 1000px;
        margin-top: 10px;
        height: 50%;
    }
    
    .movies-section {
        width: 1000px;
        margin-top: -20px;
        height: 50%;
    }
    
    .scans-section {
        width: 1000px;
        height: 50%;
        margin-top: -20px;
    }
    
    .animes-section, .movies-section, .scans-section {
        @media (max-width: 1200px) {
            width: 80%;
        }

        @media (max-width: 850px) {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title-decoration {
            width: 4px;
            height: 32px;
            background: linear-gradient(166deg, #0C7DF1 0%, #371CF1 22.92%, #A01DE5 48.44%, #EF0F64 74.48%, #DD6F0F 100%);
            margin-right: 2%;

            @media (max-width: 1100px) {
                height: 25px;
            }
        }

        h1 {
            font-family: 'Oswald';
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: white;

            @media (max-width: 1100px) {
                font-size: 18px;
            }
        }

        @media screen and (max-width: 850px) {
            width: 80%;
        }
    }

    .articles-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4%;
    
        article {
            width: 22%;
            margin-bottom: 3%;
            position: relative;
            @media (max-width: 850px) {
                width: 148px !important;
                display: inline-block !important
            }

            &:first-child {
                @media (max-width: 850px) {
                    margin-left: 10%;
                }
            }

            a {
                max-height: none;
            }
        }
        
        a {
            text-decoration: none;
            cursor: pointer;
            max-height: 46px;
        }

        h2 {
            color: white;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;

            @media (max-width: 1500px) {
                font-size: 16px;
            }

            @media (max-width: 1100px) {
                font-size: 12px;
            }
        }

        .card {
            @media (max-width: 1200px) {
                height: 280px;
            }

            @media (max-width: 1100px) {
                height: 250px;
            }

            @media (max-width: 900px) {
                height: 230px;
            }

            @media (max-width: 900px) {
                height: 210px !important;
                width: 148px !important;
                
                .wrapper img, .card {
                    height: 210px !important;
                    width: 148px !important;
                }
            }

            @media (min-width: 900px) {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                position: relative;
                height: 310px;

                &:hover {
                    .wrapper {
                        transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
                        box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);

                        &::before, &::after {
                            opacity: 1;
                        }

                        &::after {
                            height: 120px;
                        }
                    }

                    .logotitle {
                        opacity: 1;
                        transform: translate3d(0%, -50px, 100px);
                    }

                    .character {
                        opacity: 1;
                        transform: translate3d(0%, -30%, 100px);
                    }
                }
            }

            .wrapper {
                @media (min-width: 900px) {
                    transition: all 0.5s;
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                }

                &::before, &::after {
                    @media (min-width: 900px) {
                        content: "";
                        opacity: 0;
                        width: 100%;
                        height: 80px;
                        transition: all 0.5s;
                        position: absolute;
                        left: 0;
                    }
                }

                &::before {
                    @media (min-width: 900px) {
                        top: 0;
                        height: 100%;
                        background-image: linear-gradient(to top, transparent 46%, rgba(12, 13, 19, 0.5) 68%, rgba(12, 13, 19) 97%);
                    }
                }

                img {
                    @media (min-width: 900px) {
                        height: auto;
                        width: 100%;
                    }
                }
            }

            .logotitle {
                @media (min-width: 900px) {
                    max-height: 120px;
                    max-width: 100%;
                    width: auto;
                    opacity: 0;
                    transition: all 0.5s;
                }

                @media (max-width: 900px) {
                    display: none;
                }
            }

            .character {
                @media (min-width: 900px) {
                    max-height: 240px;
                    max-width: 100%;
                    opacity: 0;
                    transition: all 0.5s;
                    position: absolute;
                    z-index: -1;
                }

                @media (max-width: 1200px) {
                    max-height: 200px;
                }

                @media (max-width: 900px) {
                    display: none;
                }
            }
        }

        @media (max-width: 850px) {
            overflow: auto;
            display: flex;
            flex-wrap: nowrap;
            gap: 20px !important;
            width: 90% !important;
            position: relative;
            padding-right: 10%;
        }

        &::-webkit-scrollbar {
            @media (max-width: 850px) {
                width: 0;
                height: 0;
            }
        }
    
        &::-webkit-scrollbar-track {
            @media (max-width: 850px) {
                background-color: transparent;
            }
        }
    
        &::-webkit-scrollbar-thumb {
            @media (max-width: 850px) {
                background-color: #888;
                border-radius: 4px;
            }
        }
    }
}






