$gutter: 20px;
$caret-unit: 25px;
$bubble-bg: #8CA8D8;
$bubble-color: white;
$cite-color: $bubble-color;

@mixin caret($point, $border-width, $color) {
    $opposite: opposite-direction($point);
    border: $border-width solid transparent;
    border-#{$opposite}: $border-width solid $color;
    border-#{$point}: 0;
    height: 0;
    width: 0;
}