@function opposite-direction($directions) {
    $opposite-directions: ();
    $direction-map: (
        'top': 'bottom',
        'right': 'left',
        'bottom': 'top',
        'left': 'right',
        'center': 'center',
        'ltr': 'rtl',
        'rtl': 'ltr'
    );

    @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
        $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
        @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
    }

    @return $opposite-directions;
}

.HelpSection {
    position: fixed;
    bottom: 60px;
    right: 10%;
    padding: 0;
    border: 0;
    background-color: #00000000;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    z-index: 1;


    @media (max-width: 800px) {
        bottom: 30px;
        right: 6%;
    }

    &.pwa-mode {
        bottom: 100px;
    }

    .needhelp {
        background-color: #000000;
        border-radius: 30px;
        width: 170px;
        position: relative;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;

        @media (max-width: 1200px) {
            border-radius: 20px;
            width: 150px;
            margin-right: 25px;
            margin-bottom: 15px;
        }

        @media (max-width: 800px) {
            border-radius: 20px;
            width: 140px;
            margin-right: 25px;
            margin-bottom: 15px;
        }

        &:before {
            @include caret(bottom, 25px, #525252);
            content: '';
            // display: block;
            position: absolute;
            right: 40px;
            bottom: -10px;
            z-index: -1;
            transform-origin: center;
            transform: rotate(90deg);

            @media (max-width: 1200px) {
                @include caret(bottom, 15px, #525252);
                right: 35px;
                bottom: -7px;
            }

            @media (max-width: 800px) {
                @include caret(bottom, 15px, #525252);
                right: 30px;
                bottom: -5px;
            }

            @media (max-width: 500px) {
                @include caret(bottom, 15px, #525252);
                right: 20px;
                bottom: -5px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
            background-size: 400%;
            width: 172px;
            height: 52px;
            z-index: -1;
            animation: steam 20s linear infinite;
            border-radius: 25px;
            filter: blur(5px);

            @media (max-width: 1200px) {
                width: 152px;
                height: 47px;
            }

            @media (max-width: 800px) {
                width: 142px;
                height: 42px;
                border-radius: 20px;
            }
    
            // @media (max-width: 500px) {
            //     width: 40px;
            //     height: 40px;
            //     border-radius: 15px;
            // }
        }

        p {
            margin: 0;
            font-size: 19px;
            line-height: 50px;
            background-color: #000000;
            color: white;
            border-radius: 25px;
            text-align: center;

            @media (max-width: 1200px) {
                font-size: 16px;
                line-height: 45px;
            }

            @media (max-width: 800px) {
                font-size: 15px;
                line-height: 40px;
            }
        }

        .closeHelp {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: red;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            cursor: pointer;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .HelpWindow {
        background-color: #000000;
        width: 280px;
        height: 400px;
        border-radius: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1200px) {
            border-radius: 20px;
            margin-right: 25px;
            margin-bottom: 15px;
        }

        &:before {
            @include caret(bottom, 25px, #525252);
            content: '';
            // display: block;
            position: absolute;
            right: 70px;
            bottom: 100px;
            z-index: -1;
            transform-origin: center;
            transform: rotate(90deg);

            @media (max-width: 1200px) {
                @include caret(bottom, 15px, #525252);
                right: 60px;
                bottom: 78px;
            }

            @media (max-width: 800px) {
                @include caret(bottom, 15px, #525252);
                right: 55px;
                bottom: 70px;
            }

            @media (max-width: 500px) {
                @include caret(bottom, 15px, #525252);
                right: 45px;
                bottom: 60px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
            background-size: 400%;
            width: 282px;
            height: 402px;
            z-index: -1;
            animation: steam 20s linear infinite;
            border-radius: 25px;
            filter: blur(5px);
        }

        .closeBtn {
            align-self: flex-end;
            margin-right: 15px;
            margin-top: 15px;
            cursor: pointer;
        }

        .Messages {
            height: 80%;
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow-y: hidden;
            scroll-behavior: smooth;

            scroll-margin-bottom: 9999px;

            img {
                width: 30px;
                height: 30px;
            }

            p {
                color: white;
                font-size: 13px;
                margin: 0;
                padding: 7px 10px;
                border-radius: 15px;
                max-width: 50%;
                overflow-x: hidden;
                text-overflow: ellipsis;

                a {
                    color: lightblue;
                }
            }

            .banji {
                display: flex;
                flex-direction: row;

                p {
                    background-color: #1f1e1e;
                }
            }

            .yours {
                display: flex;
                flex-direction: row-reverse;

                p {
                    margin-right: 7px;
                    background-color: #424242;
                }
            }
        }

        .Separator {
            border: 2px solid #333337;
            width: calc(100% - 4px);
            height: 0;
            margin-top: 7px;
            margin-bottom: 7px;
        }

        .AskSection {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 7px;

            button {
                font-size: 12px;
                border: 0;
                border-radius: 15px;
                height: 20px;
                width: 46%;
                background: #1f1e1e;
                color: white;
                margin-bottom: 7px;
                cursor: pointer;
            }
        }
    }

    .BanjiTete {
        width: 80px;
        height: 80px;
        align-self: flex-end;
        cursor: pointer;
        -webkit-filter: drop-shadow(1px 1px 5px white)
                        drop-shadow(-1px -1px 5px white);
        filter: drop-shadow(1px 1px 5px white) 
                drop-shadow(-1px -1px 5px white);
        animation: float 5s ease-in-out infinite;

        @media (max-width: 1200px) {
            width: 70px;
            height: 70px;
            -webkit-filter: drop-shadow(1px 1px 4px white)
                            drop-shadow(-1px -1px 4px white);
            filter: drop-shadow(1px 1px 4px white) 
                    drop-shadow(-1px -1px 4px white);
        }

        @media (max-width: 800px) {
            width: 60px;
            height: 60px;
            -webkit-filter: drop-shadow(1px 1px 3px white)
                            drop-shadow(-1px -1px 3px white);
            filter: drop-shadow(1px 1px 3px white) 
                    drop-shadow(-1px -1px 3px white);
        }

        @media (max-width: 500px) {
            width: 50px;
            height: 50px;
        }
    }
}
