.Menufilm {
  .film-section {
    width: 1000px;
    margin-top: 10px;
    height: 50%;

    @media (max-width: 1200px) {
      width: 80%;
    }

    @media (max-width: 900px) {
      width: 80%;
    }

    @media (max-width: 850px) {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title-decoration {
        width: 4px;
        height: 32px;
        background: linear-gradient(
          166deg,
          #0c7df1 0%,
          #371cf1 22.92%,
          #a01de5 48.44%,
          #ef0f64 74.48%,
          #dd6f0f 100%
        );
        margin-right: 2%;

        @media (max-width: 1100px) {
          height: 25px;
        }
      }

      h1 {
        font-family: "Oswald";
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: white;

        @media (max-width: 1100px) {
          font-size: 18px;
        }
      }

      @media (max-width: 850px) {
        width: 80%;
      }
    }

    .film-container {
      color: white;
      display: flex;
      gap: 30px;
      width: 80%;

      @media (max-width: 900px) {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }

      img {
        @media (max-width: 900px) {
          max-height: 350px;
          object-fit: contain;
        }
      }

      h1 {
        font-size: 20px;
        margin-bottom: 10px;

        @media (max-width: 900px) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      h2 {
        font-size: 32px;
        margin: 0;

        @media (max-width: 900px) {
          font-size: 25px;
        }
      }

      p {
        text-align: justify;

        @media (max-width: 900px) {
          font-size: 12px;
        }
      }

      div {
        & > a {
          max-width: 109px;

          @media (min-width: 850px) {
            position: relative;
            display: block;
          }

          &:hover:before,
          &:hover:after {
            @media (min-width: 850px) {
              content: "";
              position: absolute;
              left: -1px;
              top: -1px;
              background: linear-gradient(
                45deg,
                #fb0094,
                #0000ff,
                #00ff00,
                #ffff00,
                #ff0000,
                #fb0094,
                #0000ff,
                #00ff00,
                #ffff00,
                #ff0000
              );
              background-size: 400%;
              width: 111px;
              height: 48px;
              z-index: -1;
              animation: steam 20s linear infinite;
              border-radius: 8px;
            }
          }

          &:hover:after {
            @media (min-width: 850px) {
              filter: blur(50px);
            }
          }
        }
      }
    }
  }
}

.Film {
  width: 80% !important;
  align-self: center;
  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    align-self: center;
  }
  .film-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    align-self: center;

    @media (max-width: 1500px) {
      width: 100%;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 46px;
      margin-right: 30px;

      &.second {
        @media screen and (max-width: 850px) {
          flex-direction: column;
          align-items: flex-start;
          height: auto;
        }
      }

      &:nth-child(2n) {
        margin-bottom: 5px;
      }

      .left-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 46px;
        width: 50%;

        @media screen and (max-width: 850px) {
          justify-content: flex-start;
          width: 100%;
        }
      }

      .right-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 46px;
        width: 50%;
        position: relative;

        @media screen and (max-width: 850px) {
          justify-content: flex-start;
          width: 100%;
        }

        h2 {
          font-family: "Oswald";
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: white;
          margin-right: 10px;

          @media (max-width: 1100px) {
            font-size: 18px;
          }
        }

        .select-container {
          position: relative;
          display: inline-block;
          border-radius: 8px;

          &::before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            height: 48px;
            width: 112px;
            border-radius: 8px;
            z-index: -1;
            background-image: linear-gradient(
              to right,
              #0c7df1 0%,
              #371cf1 22.92%,
              #a01de5 48.44%,
              #ef0f64 74.48%,
              #dd6f0f 100%
            );

            @media (max-width: 1100px) {
              height: 32px;
            }
          }

          select {
            height: 46px;
            width: 110px;
            background-color: black;
            color: white;
            border: 0;
            text-align: center;
            padding: 8px;
            border-radius: 8px;
            font-size: 14px;
            letter-spacing: 0.1em;
            font-family: Oswald;

            @media (max-width: 1100px) {
              height: 30px;
              padding: 4px;
            }

            option {
              height: 40px;
              text-align: left;
            }
          }
        }
      }

      .title-decoration {
        width: 4px;
        height: 32px;
        background: linear-gradient(
          166deg,
          #0c7df1 0%,
          #371cf1 22.92%,
          #a01de5 48.44%,
          #ef0f64 74.48%,
          #dd6f0f 100%
        );
        margin-right: 12px;

        @media (max-width: 1100px) {
          height: 25px;
        }
      }

      h1 {
        font-family: "Oswald";
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: white;
        margin: 0;

        @media (max-width: 1100px) {
          font-size: 18px;
        }
      }

      @media (max-width: 850px) {
        width: 80%;
      }
    }

    .film-iframe-section {
      display: flex;
      flex-direction: column;

      @media (max-width: 1500px) {
        width: 100%;
      }

      iframe {
        aspect-ratio: 16/9;
        width: 854px;

        @media (max-width: 1500px) {
          width: 100%;
        }
      }

      .film-report {
        text-align: center;
        margin-top: 30px;
        font-size: 10px;
        display: block;
        color: #ffffff;
      }
    }
  }
}
