// Variables
@import "_fonts";
@import "_keyframes";
@import "_mixins";

// Composants
@import "Scrollbar";
@import "Home";
@import "Header";
@import "Footer";
@import "ErrorPage";
@import "FaqLegal";
@import "Generation";
@import "Saison";
@import "ScanList";
@import "Scan";
@import "Film";
@import "EpisodeList";
@import "Episode";
@import "Help";


html {
    height: 100%;
}

body {
    background: #0D0D0E;
    margin: 0;
    height: 100%;
    font-family: 'Inter';
    font-style: normal;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    margin-top: 60px;
}