.Footer {
    height: 200px;
    width: 100%;
    background: #000;
    margin-top: 30px;

    @media (max-width: 850px) {
        display: flex;
        justify-content: center;
    }

    @media (max-width: 700px) {
        height: 150px;
    }

    @media (max-width: 500px) {
        margin-top: 0;
        height: 100px;
    }

    @media (display-mode: fullscreen) {
        background-color: rgba(0, 0, 0, 0);
        padding-bottom: 80px;
    }

    .footer-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 200px;

        @media (max-width: 850px) {
            width: 100%;
        }

        @media (max-width: 700px) {
            height: 150px;
        }

        @media (max-width: 500px) {
            height: 100px;
        }

        .footer-links {
            width: 745px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 20px;

            @media (max-width: 850px) {
                width: 70%;
            }

            @media (max-width: 700px) {
                margin-bottom: 10px;
            }

            @media (max-width: 600px) {
                margin-bottom: 0;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
            }

            @media screen and (max-width: 500px) {
                gap: 5px;
            }
            
            a {
                text-decoration: none;
                color: white;
                font-size: 15px;
                opacity: 0.6;
                transition: all 0.5s ease;

                @media (max-width: 700px) {
                    font-size: 12px;
                }
                
                @media (max-width: 600px) {
                    font-size: 11px;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        p {
            text-align: center;
            font-size: 18px;
            color: grey;

            @media (max-width: 700px) {
                font-size: 14px;
                margin-bottom: 0;
            }

            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }
    }
}